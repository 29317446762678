import Splide from '@splidejs/splide';
import { MaskInput } from "maska";

const body = document.body;
const OS = navigator.platform;

function showDialog(block) {
    block.show();
    document.body.style.overflow = "hidden";
    if (OS === "Win32" && window.innerWidth >= 1236) {
        body.style.paddingRight = "17px";
    }
}

function hideDialog(block) {
    block.close();
    document.body.style.overflow = "visible";
    body.style.paddingRight = "0px";
}

const burger = document.querySelector('.header__nav_burger');
const menu = document.querySelector('.header__menu_position');
const close = document.querySelector('.header__menu_close');
const headerDialogContent = document.querySelector('.header__dialog-content');
const callMobile = document.querySelector('.header__nav_call_mobile');

burger.addEventListener('click', function () {
    showDialog(menu);
});

close.addEventListener('click', function () {
    hideDialog(menu);
    callMobile.style.bottom = '50px';
});

headerDialogContent.addEventListener('click', function (e) {
    const target = e.target;
    if (target.classList.contains('header__menu') || target.classList.contains('header__menu_nav')) {
        return;
    }
    hideDialog(menu);
});

//открытие поиска
const searchButtons = document.querySelectorAll('.header__nav_search');

const headerContainer = document.querySelector('.header');

const searchPosition = document.querySelector('.search__position');
const searchContent = document.querySelector('.search__dialog-content');
const searchInput = document.querySelector('.search__input');
const searchDelete = document.querySelector('.search__delete');
const searchModal = document.querySelector('.search__modal');
searchButtons.forEach(searchButton => {
    searchButton.addEventListener('click', function () {
        searchButtons.forEach(searchButton => {
            searchButton.querySelector('svg').classList.add('header__nav_control_svg-active');
        });

        // if(document.inner)
        if(window.innerWidth > 768) {
            if (menu.hasAttribute('open')) {

                hideDialog(menu);
                setTimeout(() => {
                    showSearch()
                }, 100);
            } else {
                showSearch();
            }
        } else {
            showSearchMobile();
        }
    });
});

searchContent.addEventListener('click', function (e) {
    hideSearch();
});

searchDelete.addEventListener('click', function () {
    searchInput.value = '';
    if(searchModal.classList.contains('search__modal-active')) {
        searchModal.classList.remove('search__modal-active');
    }
});

searchInput.addEventListener('input', function () {
    if(!searchModal.classList.contains('search__modal-active')) {
        searchModal.classList.add('search__modal-active');
    }
    if(searchInput.value === '' && searchModal.classList.contains('search__modal-active')) {
        searchModal.classList.remove('search__modal-active');
    }
});

function showSearch() {
    headerContainer.classList.add('header-search');
    showDialog(searchPosition);
    if (OS === "Win32" && window.innerWidth > 1024) {
        body.style.paddingRight = "17px";
    }
    setTimeout(() => {
        searchInput.focus();
    }, 300);
}


function hideSearch() {
    hideDialog(searchPosition);
    searchButtons.forEach(searchButton => {
        searchButton.querySelector('svg').classList.remove('header__nav_control_svg-active');
    });
    headerContainer.classList.remove('header-search');
}

const searchMobilePosition = document.querySelector('.search_mobile__position');
const searchMobileInput = document.querySelector('.search__input_mobile');
const searchMobileDelete = document.querySelector('.search__delete_mobile');
const searchMobileModal = document.querySelector('.search__modal_mobile');

function showSearchMobile() {
    showDialog(searchMobilePosition);
    setTimeout(() => {
        searchMobileInput.focus();
    }, 300);
}

function hideSearchMobile() {
    hideDialog(searchMobilePosition);
    searchButtons.forEach(searchButton => {
        searchButton.querySelector('svg').classList.remove('header__nav_control_svg-active');
    });
}

searchMobileDelete.addEventListener('click', function () {
    searchMobileInput.value = '';
    hideSearchMobile(searchMobilePosition)
});

searchMobileInput.addEventListener('input', function () {
    console.log(searchMobileInput.value);
    if(!searchMobileModal.classList.contains('search__modal-active')) {
        searchMobileModal.classList.add('search__modal-active');
    }
    if(searchMobileInput.value === '' && searchMobileModal.classList.contains('search__modal-active')) {
        searchMobileModal.classList.remove('search__modal-active');
    }
});



const callModal = document.querySelector('.call__position');

if(document.querySelector('.header__nav_call')) {
    const callButton = document.querySelector('.header__nav_call');

    callButton.addEventListener('click', function () {
        showDialog(callModal);
    });

}

const callClose = document.querySelector('.call__close');
const callContent = document.querySelector('.call-content');

callMobile.addEventListener('click', function () {
    showDialog(callModal);
});
callClose.addEventListener('click', function () {
    hideDialog(callModal);
});

callContent.addEventListener('click', function (e) {
    const target = e.target;
    if (target.closest('.call')) {
        return;
    }
    hideDialog(callModal);
});


//маска на номер телефона
new MaskInput(".call-tel");

const callTel = document.querySelector('.call-tel');
callTel.addEventListener('input', function () {
    if (callTel.value === '+7 (8') {
        callTel.value = '+7';
    }
});

//рендер окна спасибо за обращение
const callForm = document.querySelector('.call');
callForm.addEventListener('submit', function (e) {
    if(callForm.checkValidity()) {
        e.preventDefault();
        callForm.classList.add('call-send');

        callForm.querySelector('.call__title_text').classList.add('text-hide-call-send');
        callForm.querySelector('.call__title_text_send').classList.add('text-show-call-send');

        callForm.querySelector('.call__title_text_add').classList.add('text-hide-call-send');
        callForm.querySelector('.call__title_text_add_send').classList.add('text-show-call-send');

        callForm.querySelector('.product__order_inputs').classList.add('block-call-send');
        callForm.querySelector('.product__order_confirm').classList.add('block-call-send');

        callClose.classList.add('button-svg-white');
        callClose.querySelector('svg').classList.add('close-call-send');
    }
});

if(document.querySelector('.main__banner')) {
const manImg = document.querySelector('.main__banner_man');
const mainBanner = document.querySelector('.main__banner');
    manImg.addEventListener('mouseover', function () {
        mainBanner.classList.add('main__banner_blue');
    });
    manImg.addEventListener('mouseout', function () {
        mainBanner.classList.remove('main__banner_blue');
    });
}

document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('.main__new-items')) {

        let splide = new Splide('#splide1', {
            // type: "slide",
            perPage: 4,
            autoWidth: true,
            autoHeight: true,
            // rewind: true,
            pagination: false,
            gap: "16px",
            breakpoints: {
                1260: {
                    perPage: 2,
                },
                768: {
                    perPage: 1,
                }
            }
        });
        splide.mount();
    }

});

if(document.querySelector('.catalog') && window.innerWidth <= 1024) {
    const catalogMenu = document.querySelector('.catalog__menu');
    const catalogMenuHeader = document.querySelector('.catalog__menu_header');
    const catalogMenuArrow = document.querySelector('.catalog__menu_arrow');

    console.log(catalogMenuArrow);
    catalogMenuHeader.addEventListener('click', function (e) {
        console.log(e.target);
            if (catalogMenu.classList.contains('catalog__menu_is-open')) {
                catalogMenu.classList.remove('catalog__menu_is-open');
            } else {
                catalogMenu.classList.add('catalog__menu_is-open');
            }
    });
}